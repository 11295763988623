import React from 'react'
import { connect } from 'react-redux'
import {
  CCreateElement,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavTitle,
  // CSidebarMinimizer,
  CSidebarNavDropdown,
  CSidebarNavItem,
  CInput,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import navigation from './_nav'
import { searchStr } from 'src/actions';
import { useLocation } from 'react-router-dom'

const TheSidebar = (props) => {
  const show = props.changeSideBarState?.sidebarShow;
  const location = useLocation();

  const changeHandler = (event) => {
    props.dispatch(searchStr(event.target.value));
  }

  const handleClick = () => {
    props.dispatch(searchStr(''))
  }

  return (
    <CSidebar
      show={show}
      onShowChange={(val) => props.dispatch({ type: 'set', sidebarShow: val })}
    >
      <CSidebarBrand onClick={handleClick} className="d-md-down-none" to="/">
        <img src=".././logo.png" className="c-sidebar-brand-full" alt="LatestRage" />
        <CIcon
          className="c-sidebar-brand-minimized"
          name="sygnet"
          height={35}
        />
      </CSidebarBrand>
      <CSidebarNav>
        {(location.pathname === "/" || location.pathname === "/catalogue") &&
          <CInput
            type="text"
            placeholder="Search..."
            className="search_bar"
            name='search'
            value={props.locationSet.searchStr || ''}
            onChange={changeHandler}
          />}
        <CCreateElement
          items={navigation}
          components={{
            CSidebarNavDivider,
            CSidebarNavDropdown,
            CSidebarNavItem,
            CSidebarNavTitle
          }}
        />
      </CSidebarNav>
      {/* <CSidebarMinimizer className="c-d-md-down-none"/> */}
    </CSidebar>
  )
}

const select = state => state;
export default connect(select)(React.memo(TheSidebar))
