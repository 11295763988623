const _nav =  [
  {
    _tag: 'CSidebarNavItem',
    name: 'Online Price List',
    href: 'https://latestragelivepricelist.com/',
    target:"_blank",
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Sales Policy',
    to: '/sales-policy',
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Return Policy',
    to: '/return-policy',
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Boxing Charges',
    to: '/boxing-charges',
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Logos',
    to: '/logos',
  },
  {
    _tag: 'CSidebarNavTitle',
    _children: ['Forms:'],
    className: "forms_li_title"
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Credit Card',
    href: 'https://latestrage.net/pricelist/images/download_links/LR-CC-ACCT-HOLDER.pdf',
    target: '_blank',
    className: "forms_li"
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'RGA',
    href: 'https://latestrage.net/pricelist/images/download_links/RGA-REQUEST.pdf',
    target: '_blank',
    className: "forms_li"
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Resale Cert',
    href: 'https://latestrage.net/pricelist/images/download_links/RESALE-CERT.pdf',
    target: '_blank',
    className: "forms_li"
  },
  {
    _tag: 'CSidebarNavTitle',
    _children: ['Partner Links']
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'The Tank Source',
    target: '_blank',
    href: "http://www.thetanksource.com/"
  },
  // {
  //   _tag: 'CSidebarNavItem',
  //   name: 'Knaack Depot',
  //   to: '/knaackDepot',
  // },
  // {
  //   _tag: 'CSidebarNavItem',
  //   name: 'WeatherGuard Depot',
  //   to: '/weatherGuardDepot',
  // },
  // {
  //   _tag: 'CSidebarNavItem',
  //   name: 'Snakestix',
  //   to: '/snakestix',
  // },
]

export default _nav
